import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import '../styles.scss'
export default function Partners() {
    return (
        <Layout>
                <div className="gallery-header">
                    <h4>Our <span className="blue">Partners</span></h4>
                </div>
            <PaddedLayout>
                <div className="page-wrapper">
                    <h1>This page is under development</h1>
                </div>
            </PaddedLayout>
        </Layout>
    )
}
